<template>
  <div>
    <b-modal id="modal-question" size="xl" title="Daftar Soal" body-class="pt-0" hide-footer>
      <div class="row">
        <div class="col-md-12">
          <Table
            @chooseQuestion="chooseQuestion"
            :selectedItem="selectedItem"
            purpose="modal-question"
            :userData="userData"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Table from "@/view/components/materials/questions/Table.vue";

export default {
  props: {
    selectedItem: Array,
    userData: Object
  },

  components: {
    Table,
  },

  methods: {
    chooseQuestion(value) {
      this.$emit("chooseQuestion", value);
    },
  },

  data() {
    return {};
  },
};
</script>

<style>
</style>