<template>
  <div class="animate__animated animate__fadeIn">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- Konten List -->
          <div v-if="content == 'list'">
            <div class="row animate__animated animate__fadeIn">
              <div class="col-12 pt-0">

                <div class="d-flex justify-content-between align-items-center mt-3 mb-6 px-8">
                  <div>
                    <div class="alert-icon d-inline">
                      <span class="svg-icon svg-icon-lg">
                        <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
                      </span>
                    </div>
                    <div class="alert-text d-inline">Daftar <b>Soal</b></div>
                  </div>
                  <div>
                    <!-- <b-button
                    squared
                    variant="primary"
                    @click="handleAdd"
                    v-if="btnAccess"
                    >Tambah</b-button> -->
                  </div>
                </div>

                <Table
                  purpose="master"
                  :userData="user_data"
                  @handleContentUpdate="handleContentUpdate"
                  @handleContentDetail="handleContentDetail"
                  @handleContentAdd="handleAdd"
                />
              </div>
            </div>
          </div>
          <!-- Akhir::Konten List -->

          <!-- detail -->
          <div v-if="content == 'detail'">
            <div class="row animate__animated animate__fadeIn">
              <div class="col-12">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h5
                    class="
                      font-weight-bolder
                      text-dark
                      font-size-h6 font-size-h3-lg
                    "
                  >
                    Detail Soal
                  </h5>

                  <table class="table mt-8">
                    <tr>
                      <td class="pr-1">
                        <span class="font-weight-bold">Soal</span>
                      </td>
                      <td class="pl-0 pr-0" width="10">
                        <span class="font-weight-bold">:</span>
                      </td>
                      <td class="pl-2">
                        <span>{{ detail.title }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <span class="font-weight-bold">Jenis Pertanyaan</span>
                      </td>
                      <td class="pl-0 pr-0" width="10">
                        <span class="font-weight-bold">:</span>
                      </td>
                      <td class="pl-2">
                        <span>{{ detail.question_type_name }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <span class="font-weight-bold">Topik</span>
                      </td>
                      <td class="pl-0 pr-0" width="10">
                        <span class="font-weight-bold">:</span>
                      </td>
                      <td class="pl-2">
                        <span>{{ detail.topic_name }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <span class="font-weight-bold">Penjelasan</span>
                      </td>
                      <td class="pl-0 pr-0" width="10">
                        <span class="font-weight-bold">:</span>
                      </td>
                      <td class="pl-2">
                        <span>{{ detail.explanation }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <span class="font-weight-bold">Petunjuk</span>
                      </td>
                      <td class="pl-0 pr-0" width="10">
                        <span class="font-weight-bold">:</span>
                      </td>
                      <td class="pl-2">
                        <span>{{ detail.hint }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <span class="font-weight-bold">Tag</span>
                      </td>
                      <td class="pl-0 pr-0" width="10">
                        <span class="font-weight-bold">:</span>
                      </td>
                      <td class="pl-2">
                        <span>{{ detail.tag }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <span class="font-weight-bold">Soal</span>
                      </td>
                      <td class="pl-0 pr-0" width="10">
                        <span class="font-weight-bold">:</span>
                      </td>
                      <td class="pl-2">
                        <span>{{ detail.content }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <span class="font-weight-bold">Jawaban</span>
                      </td>
                      <td class="pl-0 pr-0" width="10">
                        <span class="font-weight-bold">:</span>
                      </td>
                      <td class="pl-2">
                        <!-- Single Text || Multiple Text -->
                        <template
                          v-if="
                            detail.question_type_id == 1 ||
                            detail.question_type_id == 2
                          "
                        >
                          <span>{{ detail.answer }}</span>
                        </template>

                        <!-- True Or False -->
                        <template v-if="detail.question_type_id == 3">
                          <b-form-radio-group
                            id="radio-group-1"
                            v-model="detail.answer"
                            :options="detail.answer_choices_display"
                            name="radio-options"
                            disabled
                          ></b-form-radio-group>
                        </template>

                        <!-- Single Choice -->
                        <template v-if="detail.question_type_id == 4">
                          <div
                            class="d-flex align-items-center"
                            v-for="(answer, index) in detail.answer_choices_display"
                            :key="answer.value"
                          >
                            <div>
                              <b-form-radio
                                :key="answer.value"
                                :name="'some-radios-' + index"
                                :value="answer.value"
                                v-model="detail.answer"
                                disabled
                                >{{ answer.text }}</b-form-radio
                              >
                            </div>
                          </div>
                        </template>

                        <!-- Multiple Choice -->
                        <template
                          v-if="detail.question_type_id == 5"
                        >
                          <table>
                            <tr
                              v-for="answer in detail.answer_choices_display"
                              :key="answer.value"
                            >
                              <th>
                                <b-form-checkbox
                                  :checked="answer.checked"
                                  :id="`checkbox-pick-item-${answer.value}`"
                                  :name="`checkbox-pick-item-${answer.value}`"
                                  disabled
                                >
                                </b-form-checkbox>
                              </th>
                              <th>
                                <span style="cursor: pointer">{{
                                  answer.text
                                }}</span>
                              </th>
                            </tr>
                          </table>
                        </template>

                        <!-- Completion -->
                        <template v-if="detail.question_type_id == 6">
                          <span>
                            <ul>
                              <li
                                v-for="answer in detail.answer"
                                :key="answer.value"
                              >
                                {{ answer.value + ". " + answer.text }}
                              </li>
                            </ul>
                          </span>
                        </template>
                      </td>
                    </tr>
                  </table>

                  <v-spacer></v-spacer>
                  <b-button
                    type="button"
                    class="ml-2"
                    variant="primary"
                    @click="handleContent('list')"
                  >
                    Tutup
                  </b-button>
                  <b-button
                    type="button"
                    class="ml-2"
                    variant="success"
                    @click="handleContentUpdate(detail.id)"
                    v-if="btnAccess"
                  >
                    Edit
                  </b-button>
                </div>
                <!--end: Wizard Body-->
              </div>
            </div>
          </div>
          <!--akhir::detail-->

          <!-- Konten Tambah -->
          <div v-if="content == 'tambah'">
            <h4 class="mb-5">Tambah Soal Baru</h4>
            <Form
              :post="post"
              @handleContent="handleContent"
              @dataAdd="dataAdd"
            />
          </div>
          <!-- Akhir::Konten Tambah -->

          <!-- Konten Ubah -->
          <div v-if="content == 'ubah'">
            <h4 class="mb-5">Edit Soal</h4>
            <FormEdit
              :post="edit"
              @handleContent="handleContent"
              @dataAdd="dataAdd"
            />
          </div>
          <!-- Akhir::Konten Tambah -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import Table from "@/view/components/materials/questions/Table.vue";
import Form from "@/view/components/materials/questions/Form.vue";
import FormEdit from "@/view/components/materials/questions/FormEdit.vue";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  components: {
    Table,
    Form,
    FormEdit,
  },
  data() {
    return {
      // data
      questions: [],
      detail: [],
      edit: [],
      topics: [],
      question_types: [],

      post: {
        title: "",
        content: "",
        question_type_id: "",
        answer: "",
        answer_choices: "",
        explanation: "",
        hint: "",
        tag: "",
        topic_id: "",
      },
      user_data:[],
      // format
      content: null,
      // ckeditor
      editor: DecoupledEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "indent",
          "outdent",
          "|",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
      // access
      btnAccess: false
    };
  },
  methods: {
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    // handle action button
    handleContent(content) {
      this.content = content;
      this.resetPostOnNew();
      this.resetEditOnNew();
    },
    handleContentUpdate(id) {
      this.getDataById(id);
      this.edit["_method"] = "put";
      this.content = "ubah";
    },
    async handleContentDetail(id) {
      this.detail = await module.get("api/questions/" + id);
      // If Data Not Found
      if (this.detail == null) {
        // Redirect To List
        this.content = "list";
      } else {
        // Multiple Choice
        if (this.detail.question_type_id == 5) {
          for (let a = 0; a < this.detail.answer_choices_display.length; a++) {
            for (let b = 0; b < this.detail.answer_display.length; b++) {
              if (
                this.detail.answer_choices_display[a].value ==
                this.detail.answer_display[b]
              ) {
                this.detail.answer_choices_display[a].checked = true;
              }
            }
          }
        }
        // Completion
        if (this.detail.question_type_id == 6) {
          this.detail.answer = JSON.parse(this.detail.answer_choices);
          //("answer", this.detail.answer);
        }
        this.content = "detail";
      }
    },
    handleAdd() {
      this.content = "tambah";
    },
    resetPostOnNew() {
      let self = this;
      Object.keys(this.post).forEach(function (key, index) {
        if (key === "formData") {
          self.post[key] = new FormData();
        } else {
          self.post[key] = "";
        }
        // //("post", this.post)
        // //("self", self.post)
      });
    },
    resetEditOnNew() {
      let self = this;
      Object.keys(this.edit).forEach(function (key, index) {
        if (key === "formData") {
          self.edit[key] = new FormData();
        } else {
          self.edit[key] = "";
        }
        // //("edit", this.edit)
      });
    },

    // system operation

    async getDataById(id) {
      this.edit = await module.get("api/questions/" + id);
      // If Data Not Found
      if (this.edit == null) {
        // Redirect To List
        this.content = "list";
      } else {
        // Multiple Choice
        if (this.edit.question_type_id == 5) {
          for (let a = 0; a < this.edit.answer_choices_display.length; a++) {
            for (let b = 0; b < this.edit.answer_display.length; b++) {
              if (
                this.edit.answer_choices_display[a].value ==
                this.edit.answer_display[b]
              ) {
                this.edit.answer_choices_display[a].checked = true;
              }
            }
          }
        }
        this.edit["_method"] = "put";
        this.detail = this.edit;
      }
    },

    async dataAdd() {
      this.resetPostOnNew();
      this.content = "list";
    },

    async dataUpdate(id) {
      // Make Request
      let response = await module.submit(this.edit, "api/questions/" + id);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$root.$emit("refreshQuestion");
        this.resetEditOnNew();
        this.handleContent("list");
      }
    },

    async dataDelete(id) {
      // Delete Data
      let result = await module.delete("api/questions/" + id);
      // If Deleted
      if (result) {
        this.getAllData();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "2005") {
          this.btnAccess = true
        }
      }
    },
  },
  mounted() {
    this.content = "list";
    this.user_data = getUser()
    this.checkAccess()
  },
  computed: {},
};
</script>

<style scoped>
table .btn {
  margin-right: 5px;
}

table .btn {
  margin-right: 5px;
}

.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

.document-editor__editable-container {
  padding: calc(2 * var(--ck-spacing-large));
  background: var(--ck-color-base-foreground);

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.document-editor__editable-container .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 15.8cm;
  min-height: 21cm;

  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 0 auto;
}

.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc(
    1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
  );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor
  .ck-heading-dropdown
  .ck-list
  .ck-button:not(.ck-heading_paragraph)
  .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: normal;
}

.document-editor .ck-content h2 {
  line-height: 1.37em;
  padding-top: 0.342em;
  margin-bottom: 0.142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: normal;
  color: hsl(203, 100%, 50%);
}

.document-editor
  .ck-heading-dropdown
  .ck-heading_heading2.ck-on
  .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
  line-height: 1.86em;
  padding-top: 0.171em;
  margin-bottom: 0.357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: bold;
}

.document-editor .ck-content h4 {
  line-height: 1.24em;
  padding-top: 0.286em;
  margin-bottom: 0.952em;
}

/* Set the styles for "Paragraph". */
.document-editor .ck-content p {
  font-size: 1em;
  line-height: 1.63em;
  padding-top: 0.5em;
  margin-bottom: 1.13em;
}

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc(2 * var(--ck-spacing-large));
  margin-right: calc(2 * var(--ck-spacing-large));
}
</style>