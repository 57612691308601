var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animate__animated animate__fadeIn"},[_c('div',[_c('div',{staticClass:"row justify-content-end mt-3"},[_c('div',{staticClass:"col-md-3"},[_c('treeselect',{attrs:{"multiple":false,"placeholder":"Pilih Topik","options":_vm.topics},on:{"input":_vm.filterByTopic},model:{value:(_vm.filter.topic_id),callback:function ($$v) {_vm.$set(_vm.filter, "topic_id", $$v)},expression:"filter.topic_id"}})],1),_c('div',{staticClass:"col-md-4"},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',{staticClass:"bg-danger text-white rounded-0",staticStyle:{"cursor":"pointer"},attrs:{"squared":""}},[_c('span',{on:{"click":_vm.filterReset}},[_vm._v("Reset")])])]},proxy:true}])},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Saring Berdasar Judul"},on:{"keyup":_vm.filterByName},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1),(_vm.btnAccess && _vm.purpose == 'master')?_c('div',{staticClass:"col-md-1 mr-8"},[(_vm.btnAccess)?_c('b-button',{attrs:{"squared":"","variant":"primary"},on:{"click":_vm.handleContentAdd}},[_vm._v("Tambah")]):_vm._e()],1):_vm._e()]),_c('b-table',{staticClass:"mt-3",attrs:{"striped":"","hover":"","items":_vm.quizzes,"fields":_vm.purpose === 'master' ? _vm.fields : _vm.fieldsModal,"responsive":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
              width: field.key === 'action' ? '25%' : '',
            })})})}},{key:"cell(duration)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.duration + " " + data.item.duration_unit_name)+" ")]}},{key:"cell(action)",fn:function(data){return [(_vm.purpose === 'master')?[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-info",attrs:{"size":"sm","title":"Detail"},on:{"click":function($event){return _vm.handleContentDetail(data.item.id)}}},[_c('i',{staticClass:"fas fa-eye px-0"})]),(_vm.btnAccess)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-success",attrs:{"size":"sm","title":"Edit"},on:{"click":function($event){return _vm.handleContentUpdate(data.item.id)}}},[_c('i',{staticClass:"fas fa-edit px-0"})]):_vm._e(),(_vm.btnAccess)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-danger",attrs:{"size":"sm","title":"Hapus"},on:{"click":function($event){return _vm.dataDelete(data.item.id)}}},[_c('i',{staticClass:"fas fa-trash px-0"})]):_vm._e()]:_vm._e(),(_vm.purpose === 'modal-quiz')?[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-info",attrs:{"size":"sm","title":"Detail"},on:{"click":function($event){return _vm.$emit('handleDetailModal', data.item.id)}}},[_c('i',{staticClass:"fas fa-eye px-0"})])]:_vm._e()]}},{key:"cell(select)",fn:function(data){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-info",attrs:{"size":"sm","title":"Detail"},on:{"click":function($event){return _vm.chooseQuiz({
                id: data.item.id,
                title: data.item.title,
                total_score: data.item.total_score,
              })}}},[_vm._v("Pilih")])]}}])}),(_vm.quizzes.length != 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},on:{"page-click":_vm.pageOnClick},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }