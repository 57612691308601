<template>
  <div class="animate__animated animate__fadeIn">
    <div class="col-12">
      <!--begin: Wizard Step 1-->
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
          Detail Kuis
        </h5>

        <table class="table mt-8">
          <tr>
            <td class="pr-1">
              <span class="font-weight-bold">Judul</span>
            </td>
            <td class="pl-0 pr-0" width="10">
              <span class="font-weight-bold">:</span>
            </td>
            <td class="pl-2">
              <span>{{ detail.title }}</span>
            </td>
          </tr>
          <tr>
            <td class="pr-1">
              <span class="font-weight-bold">Durasi</span>
            </td>
            <td class="pl-0 pr-0" width="10">
              <span class="font-weight-bold">:</span>
            </td>
            <td class="pl-2">
              <span>{{ detail.duration }}</span>
            </td>
          </tr>
          <tr>
            <td class="pr-1">
              <span class="font-weight-bold">Satuan Durasi</span>
            </td>
            <td class="pl-0 pr-0" width="10">
              <span class="font-weight-bold">:</span>
            </td>
            <td class="pl-2">
              <span>{{ detail.duration_unit_name }}</span>
            </td>
          </tr>
          <tr>
            <td class="pr-1">
              <span class="font-weight-bold">Topik</span>
            </td>
            <td class="pl-0 pr-0" width="10">
              <span class="font-weight-bold">:</span>
            </td>
            <td class="pl-2">
              <span>{{ detail.topic_name }}</span>
            </td>
          </tr>
        </table>

        <div class="alert-icon d-inline">
          <span class="svg-icon svg-icon-lg">
            <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
          </span>
        </div>
        <div class="alert-text d-inline">
          Daftar <b>Soal</b> dan <b>Jawaban</b>
        </div>

        <b-table
          borderless
          :items="detail.questions"
          :fields="fieldsQuestion"
          :per-page="perPage"
          :current-page="currentPage"
          responsive
        >
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{
                width: field.key === 'number' ? '5%' : '',
              }"
            />
          </template>

          <template #cell(content)="data">
            <!-- Single text || Multiple text -->
            <template
              v-if="
                data.item.question_type_id == 1 ||
                data.item.question_type_id == 2
              "
            >
              <span>{{ data.item.content }}</span> <br />
              <span class="d-block mt-2">Jawaban:</span>
              <span>{{ data.item.answer }}</span>
            </template>

            <!-- True Or False -->
            <template v-if="data.item.question_type_id == 3">
              <span>{{ data.item.content }}</span>
              <span class="d-block mt-2">Jawaban:</span>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  :id="'radio-group-' + data.item.number"
                  v-model="data.item.answer"
                  :options="data.item.answer_choices_display"
                  :aria-describedby="ariaDescribedby"
                  :name="'radio-options-' + data.item.number"
                  disabled
                ></b-form-radio-group>
              </b-form-group>
            </template>

            <!-- Single Choice -->
            <template v-if="data.item.question_type_id == 4">
              <span>{{ data.item.content }}</span>
              <span class="d-block mt-2">Jawaban:</span>
              <div
                class="d-flex align-items-center"
                v-for="(answer, index) in data.item.answer_choices_display"
                :key="answer.value"
              >
                <div>
                  <b-form-radio
                    :key="answer.value"
                    :name="'some-radios-' + index"
                    :value="answer.value"
                    v-model="data.item.answer"
                    disabled
                    >{{ answer.text }}</b-form-radio
                  >
                </div>
              </div>
            </template>

            <!-- Multiple Choice -->
            <template v-if="data.item.question_type_id == 5">
              <span>{{ data.item.content }}</span>
              <span class="d-block mt-2">Jawaban:</span>
              <div
                class="d-flex align-items-center"
                v-for="answer in data.item.answer_choices_display"
                :key="answer.value"
              >
                <div>
                  <b-form-checkbox
                    :checked="answer.checked"
                    :id="`checkbox-pick-item-${answer.value}`"
                    :name="`checkbox-pick-item-${answer.value}`"
                    disabled
                  >
                  </b-form-checkbox>
                </div>
                <div>
                  <td class="pl-0">
                    <span style="cursor: pointer">{{ answer.text }}</span>
                  </td>
                </div>
              </div>
            </template>
            <!-- Completion -->
            <template v-if="data.item.question_type_id == 6">
              <span>{{ data.item.content }}</span>
              <span class="d-block mt-2">Jawaban:</span>
              <ul>
                <li
                  v-for="answer in data.item.answer_choices_display"
                  :key="answer.value"
                >
                  {{ answer.value + ". " + answer.text }}
                </li>
              </ul>
            </template>
          </template>
        </b-table>
        <b-pagination
          v-if="detail.questions.length != 0"
          v-model="currentPage"
          :total-rows="detail.questions.length"
          :per-page="perPage"
          class="mt-4"
        ></b-pagination>

        <v-spacer class="mt-15"></v-spacer>
        <b-button
          type="button"
          class="ml-2"
          variant="primary"
          @click="handleContent('list')"
          v-if="purpose == 'master'"
        >
          Tutup
        </b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="success"
          @click="handleContentUpdate(detail.id)"
          v-if="purpose == 'master' && btnAccess"
        >
          Edit
        </b-button>
      </div>
      <!--end: Wizard Body-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: Object,
    purpose: String,
  },
  data() {
    return {
      fieldsQuestion: [
        {
          key: "number",
          label: "",
        },
        { key: "content", label: "" },
      ],
      perPage: 20,
      totalRows: 1,
      currentPage: 1,
      // access
      btnAccess: false
    };
  },
  methods: {
    handleContent() {
      this.$emit("handleContent", "list");
    },
    handleContentUpdate(id) {
      this.$emit("handleContentUpdate", id);
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "2005" || access_right[a] == "7007") {
          this.btnAccess = true
        }
      }
    },
  },
  mounted(){
    // access
    this.checkAccess()
  },
  watch: {
    detail: function (newVal, oldVal) {
      // watch it
      //("Detail changed: ", newVal, " | was: ", oldVal);
    },
  },
};
</script>

<style>
</style>