<template>
  <div class="vue-html2pdf" v-if="pdfRoute">
    <section
      class="layout-container"
      :class="{
        'show-layout': false,
        'unset-all': !true,
      }"
    >
      <section
        class="content-wrapper"
        :style="`width: 800px;`"
        ref="pdfContent"
      >
        <slot name="pdf-content" />
      </section>
    </section>

    <transition name="transition-anim">
      <section class="pdf-preview">
        <button @click.self="closePreview()">&times;</button>

        <iframe :src="pdfRoute" width="100%" height="100%" />
      </section>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    pdfFile: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      pdfRoute: null,
    };
  },
  methods: {
    closePreview() {
      this.pdfRoute = null;
      this.$emit('closePreview')
    },
  },
  mounted(){
      this.pdfRoute = this.pdfFile
  },
  watch: {
    pdfFile: function (newVal, oldVal) {
      // watch it
      this.pdfRoute = this.pdfFile;
    },
  },
};
</script>

<style lang="scss" scoped>
.vue-html2pdf {
  .layout-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: -100vw;
    top: 0;
    z-index: -9999;
    background: rgba(95, 95, 95, 0.8);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;

    &.show-layout {
      left: 0vw;
      z-index: 9999;
    }

    &.unset-all {
      all: unset;
      width: auto;
      height: auto;
    }
  }

  .pdf-preview {
    position: fixed;
    width: 60%;
    min-width: 600px;
    height: 80vh;
    top: 93px;
    z-index: 9999999;
    left: 64%;
    transform: translateX(-50%);
    border-radius: 5px;
    box-shadow: 0px 0px 10px #00000048;

    button {
      position: absolute;
      top: -20px;
      left: -15px;
      width: 35px;
      height: 35px;
      background: #555;
      border: 0;
      box-shadow: 0px 0px 10px #00000048;
      border-radius: 50%;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      cursor: pointer;
    }

    iframe {
      border: 0;
    }
  }

  .transition-anim-enter-active,
  .transition-anim-leave-active {
    transition: opacity 0.3s ease-in;
  }

  .transition-anim-enter,
  .transition-anim-leave-to {
    opacity: 0;
  }
}
</style>