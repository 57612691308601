<template>
  <div class="animate__animated animate__fadeIn">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- Konten List -->
          <div v-if="content == 'list'">
            <div class="row animate__animated animate__fadeIn">
              <div class="col-12 pt-0">
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    mt-3
                    mb-6
                    px-8
                  "
                >
                  <div>
                    <div class="alert-icon d-inline">
                      <span class="svg-icon svg-icon-lg">
                        <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
                      </span>
                    </div>
                    <div class="alert-text d-inline">Daftar <b>Topik</b></div>
                  </div>
                  <div>
                    <!-- <b-button
                    squared
                    variant="primary"
                    @click="handleAdd"
                    v-if="btnAccess"
                    >Tambah</b-button> -->
                  </div>
                </div>

                <div>
                  <!-- Filter -->
                  <div class="row justify-content-end mt-3">
                    <div class="col-md-3">
                      <treeselect
                        v-model="filter.topic_id"
                        :multiple="false"
                        placeholder="Pilih Parent Topik"
                        :options="topicOptions"
                        @input="filterByTopic"
                      />
                    </div>
                    <div class="col-md-4">
                      <b-input-group>
                        <b-form-input
                          type="text"
                          v-model="filter.name"
                          placeholder="Cari Nama Topik"
                          @keyup="filterByName"
                        ></b-form-input>
                        <template #append>
                          <b-input-group-text
                            squared
                            class="bg-danger text-white"
                            style="cursor: pointer"
                          >
                            <span @click="filterReset">Reset</span>
                          </b-input-group-text>
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-md-1 mr-8">
                      <b-button
                        squared
                        variant="primary"
                        @click="handleAdd"
                        v-if="btnAccess"
                        >Tambah</b-button
                      >
                    </div>
                  </div>
                  <!-- table -->
                  <b-table
                    striped
                    hover
                    :items="topics"
                    :fields="fields"
                    :per-page="perPage"
                    :current-page="currentPage"
                    class="mt-3"
                    responsive
                  >
                    <template #table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{
                          width: field.key === 'action' ? '25%' : '',
                        }"
                      />
                    </template>

                    <template #cell(name)="data">
                      <div v-if="data.item.parent_id == 0">
                        <b>{{ data.item.name }}</b>
                      </div>
                      <div v-else-if="data.item.parent_id != 0">
                        <div :class="'pl-5'">
                          {{ data.item.name }}
                        </div>
                      </div>
                    </template>

                    <template #cell(action)="data">
                      <b-button
                        size="sm"
                        class="mr-1 btn-info"
                        v-b-tooltip.hover
                        title="Detail"
                        @click="handleContentDetail(data.item.id)"
                        ><i class="fas fa-eye px-0"></i
                      ></b-button>
                      <b-button
                        size="sm"
                        class="mr-1 btn-success"
                        v-b-tooltip.hover
                        title="Edit"
                        @click="handleContentUpdate(data.item.id)"
                        v-if="btnAccess"
                        ><i class="fas fa-edit px-0"></i
                      ></b-button>
                      <b-button
                        size="sm"
                        class="btn-danger"
                        v-b-tooltip.hover
                        title="Hapus"
                        @click="materialDelete(data.item.id)"
                        v-if="btnAccess"
                        ><i class="fas fa-trash px-0"></i
                      ></b-button>
                    </template>
                  </b-table>

                  <b-pagination
                    v-if="topics.length != 0"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    class="mt-4"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
          <!-- Akhir::Konten List -->

          <!-- detail -->
          <div v-if="content == 'detail'">
            <div class="row animate__animated animate__fadeIn">
              <div class="card card-custom col-12">
                <div class="card-body p-0">
                  <!--begin: Wizard-->
                  <div
                    class="wizard wizard-2"
                    id="kt_wizard_v2"
                    data-wizard-state="step-first"
                    data-wizard-clickable="true"
                  >
                    <!--begin: Wizard Nav -->
                    <div
                      class="
                        wizard-nav
                        border-right
                        py-8
                        px-8
                        py-lg-20
                        px-lg-10
                      "
                    >
                      <div class="wizard-steps">
                        <div
                          class="wizard-step"
                          data-wizard-type="step"
                          data-wizard-state="current"
                        >
                          <img :src="detail.image" width="200" alt="" />
                        </div>
                      </div>
                    </div>
                    <!--end: Wizard Nav -->

                    <!--begin: Wizard Body -->
                    <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
                      <!--begin: Wizard Form-->
                      <div class="row">
                        <div class="offset-xxl-2 col-xxl-8">
                          <form class="form" id="kt_form">
                            <!--begin: Wizard Step 1-->
                            <div
                              class="pb-5"
                              data-wizard-type="step-content"
                              data-wizard-state="current"
                            >
                              <!-- <h4 class="font-weight-bold text-dark">
                        {{ detail.name }}
                      </h4> -->

                              <div class="card-body">
                                <table class="table table-borderless">
                                  <tr>
                                    <td class="pr-1" width="130">
                                      <span class="font-weight-bold">Nama</span>
                                    </td>
                                    <td class="pl-0">
                                      <span class="font-weight-bold">:</span>
                                    </td>
                                    <td class="pl-2">
                                      <span class="text-muted">{{
                                        detail.name
                                      }}</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="pr-1" width="130">
                                      <span class="font-weight-bold"
                                        >Parent</span
                                      >
                                    </td>
                                    <td class="pl-0">
                                      <span class="font-weight-bold">:</span>
                                    </td>
                                    <td class="pl-2">
                                      <span class="text-muted">{{
                                        detail.parent_name
                                      }}</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="pr-1" width="130">
                                      <span class="font-weight-bold"
                                        >Jenis Akses</span
                                      >
                                    </td>
                                    <td class="pl-0">
                                      <span class="font-weight-bold">:</span>
                                    </td>
                                    <td class="pl-2">
                                      <span class="text-muted">{{
                                        detail.access_type_display
                                      }}</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="pr-1" width="130">
                                      <span class="font-weight-bold"
                                        >Jenis Akses</span
                                      >
                                    </td>
                                    <td class="pl-0">
                                      <span class="font-weight-bold">:</span>
                                    </td>
                                    <td class="pl-2">
                                      <span class="text-muted">{{
                                        detail.access_type_display
                                      }}</span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <!--end: Wizard Step 1-->

                            <!--begin: Wizard Actions -->
                            <div
                              class="
                                d-flex
                                justify-content-between
                                border-top
                                pt-10
                              "
                            >
                              <div>
                                <b-button
                                  type="button"
                                  class="ml-2"
                                  variant="primary"
                                  @click="handleContent('list')"
                                >
                                  Tutup
                                </b-button>
                                <b-button
                                  type="button"
                                  class="ml-2"
                                  variant="success"
                                  @click="
                                    $router.push(
                                      '/masters/collections/edit/' +
                                        $route.params.id
                                    )
                                  "
                                  v-if="btnAccess"
                                >
                                  Edit
                                </b-button>
                              </div>
                            </div>
                            <!--end: Wizard Actions -->
                          </form>
                        </div>
                        <!--end: Wizard-->
                      </div>
                    </div>
                    <!--end: Wizard Body -->
                  </div>
                  <!--end: Wizard-->
                </div>
              </div>
            </div>
          </div>
          <!--akhir::detail-->

          <!-- Konten Tambah -->
          <template v-if="content == 'tambah'">
            <h4 class="mb-5">Tambah Topik Baru</h4>
            <Form
              :post="post"
              @handleContent="handleContent"
              @dataAdd="dataAdd"
              :route="`api/topics`"
              :userData="user_data"
            />
          </template>
          <!-- Akhir::Konten Tambah -->

          <!-- Konten Ubah -->
          <template v-if="content == 'ubah'">
            <h4 class="mb-5">Edit Topik</h4>
            <Form
              :post="edit"
              @handleContent="handleContent"
              @dataAdd="dataAdd"
              :route="`api/topics/` + edit.id"
            />
          </template>
          <!-- Akhir::Konten Tambah -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import Form from "@/view/components/materials/topics/Form.vue";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  components: {
    Form,
  },
  data() {
    return {
      // data
      topics: [],
      topicsData: [],
      fixedTopics: [],
      detail: [],
      edit: [],

      post: {
        name: "",
        image: null,
        description: "",
        parent_id: 0,
        access_type: 1,
      },

      // format
      content: null,
      perPage: 20,
      totalRows: 1,
      currentPage: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        { key: "name", label: "Nama" },
        {
          key: "access_type_display",
          label: "Jenis Akses",
        },
        {
          key: "description",
          label: "Deskripsi",
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      // filter
      filter: {
        name: "",
        topic_id: "",
      },
      topicOptions: [],
      // other
      user_data: [],
      // access
      btnAccess: false,
    };
  },
  methods: {
    // handle action button
    handleContent(content) {
      this.content = content;
    },
    handleContentUpdate(id) {
      this.getMaterialById(id);
      this.content = "ubah";
    },
    async handleContentDetail(id) {
      this.detail = await module.get("api/topics/" + id);
      // If Data Not Found
      if (this.detail == null) {
        // Redirect To List
        this.content = "list";
      } else {
        this.content = "detail";
      }
    },
    handleAdd() {
      this.content = "tambah";
      //("content", this.content);
    },
    resetPostOnNew() {
      let self = this;
      Object.keys(this.post).forEach(function (key, index) {
        if (key === "formData") {
          self.post[key] = new FormData();
        } else {
          self.post[key] = "";
        }
      });
    },
    resetEditOnNew() {
      let self = this;
      Object.keys(this.edit).forEach(function (key, index) {
        if (key === "formData") {
          self.edit[key] = new FormData();
        } else {
          self.edit[key] = "";
        }
        // //("edit", this.edit)
      });
    },

    // system operation
    async getAllData() {
      this.fixedTopics = [];
      this.topicsData = [];
      this.topics = [];

      let filterParams = `&name=${this.filter.name}&topic_id=${this.filter.topic_id}`;
      let response = await module.paginate(
        `api/topics/my-topics/${getUser().id}`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      // this.totalRows = pagination.total;

      // //("respo", response.data);

      this.topicsData = response.data;

      let a, b;
      for (a = 0; a < this.topicsData.length; a++) {
        this.topics.push(this.topicsData[a]);
        this.fixedTopics = response.data[a].children;
        for (b = 0; b < this.fixedTopics.length; b++) {
          this.topics.push(this.fixedTopics[b]);
        }
      }

      let c, d;
      d = this.perPage * (this.currentPage - 1) + 1;
      for (c = 0; c < this.topics.length; c++) {
        this.topics[c].number = d + c;
      }

      //("topics", this.topics);
      this.totalRows = this.topics.length;
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.getAllData();
    },

    async getMaterialById(id) {
      this.edit = await module.get("api/topics/" + id);
      // If Data Not Found
      if (this.edit == null) {
        // Redirect To List
        this.content = "list";
      } else {
        this.edit["_method"] = "put";
        this.detail = this.edit;
      }
    },

    async dataAdd() {
      // this.fixedTopics = [];
      // this.topicsData = [];
      // this.topics = [];
      this.getAllData();
      this.resetPostOnNew();
      this.content = "list";
    },

    async dataUpdate(id) {
      // Make Request
      let response = await module.submit(this.formData, "api/topics/" + id);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.getAllData();
        this.resetEditOnNew();
        this.handleContent("list");
      }
    },

    async dataDelete(id) {
      // Delete Data
      let result = await module.delete("api/topics/" + id);
      // If Deleted
      if (result) {
        this.getAllData();
      }
    },

    async setUserData() {
      this.user_data = await getUser();
      this.getAllData();
      //("user_data", this.user_data);
    },

    async getTopicOption() {
      let response = await module.setTreeSelect(
        `api/topics/my-topics/${getUser().id}`
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.topicOptions = response.data;
        this.topicOptions.unshift({
          label: "Cari Parent Topik",
          id: "",
          isDisabled: true,
        });
      }
    },

    filterByName() {
      this.getAllData();
    },
    async filterByTopic(evt) {
      //("action", evt);
      if (!evt) {
        this.filter.topic_id = await "";
        this.getAllData();
      } else {
        this.filter.topic_id = await evt;
        this.getAllData();
        //("ok", this.filter.topic_id);
      }
    },

    filterReset() {
      this.filter.topic_id = "";
      this.filter.name = "";
      this.getAllData();
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "2011") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.content = "list";
    this.setUserData();
    this.checkAccess();
    this.getTopicOption();
  },
  computed: {},
};
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<style>
.wizard-steps {
  height: 230px;
  width: 230px;
}

.wizard-steps img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>