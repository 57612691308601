<template>
  <div>
    <div class="row">
      <div class="col-12">
        <b-card
          bg-variant="primary"
          text-variant="white"
          title="Bank Materi dan Soal"
        >
        </b-card>
        <div class="card">
          <b-tabs content-class="" class="">
            <div class="container">
              <b-tab active v-if="lessonView">
                <template #title>
                  <span class="d-md-none d-block"
                    ><i class="fas fa-book-open"></i
                  ></span>
                  <span class="d-none d-md-block">Pelajaran</span>
                </template>
                <Lesson />
              </b-tab>
              <b-tab v-if="questionView">
                <template #title>
                  <span class="d-md-none d-block"
                    ><i class="fas fa-file-invoice"></i
                  ></span>
                  <span class="d-none d-md-block">Bank Soal</span>
                </template>
                <Question />
              </b-tab>
              <b-tab v-if="quizView">
                <template #title>
                  <span class="d-md-none d-block"
                    ><i class="fas fa-question-circle"></i
                  ></span>
                  <span class="d-none d-md-block">Kuis</span>
                </template>
                <Quiz />
              </b-tab>
              <b-tab v-if="materialView">
                <template #title>
                  <span class="d-md-none d-block"
                    ><i class="fas fa-file-alt"></i
                  ></span>
                  <span class="d-none d-md-block">Materi Pendukung</span>
                </template>
                <Material />
              </b-tab>
              <b-tab v-if="topicView">
                <template #title>
                  <span class="d-md-none d-block"
                    ><i class="fab fa-stack-exchange"></i
                  ></span>
                  <span class="d-none d-md-block">Topik</span>
                </template>
                <Topics />
              </b-tab>
              <!-- <b-tab title="Topik"><TopicOnePage /></b-tab> -->
            </div>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Lesson from "@/view/components/materials/Lesson";
import Question from "@/view/components/materials/Question";
import Quiz from "@/view/components/materials/Quiz";
import Material from "@/view/components/materials/Material";
import Topics from "@/view/components/materials/Topics";
// import TopicOnePage from "@/view/components/materials/TopicsOnePage"

export default {
  name: "materials",
  components: {
    Lesson,
    Question,
    Quiz,
    Material,
    Topics,
    // TopicOnePage
  },
  data() {
    return {
      // access
      lessonView: false,
      questionView: false,
      quizView: false,
      materialView: false,
      topicView: false
    };
  },
  methods: {
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "2001" || access_right[a] == "2002") {
          this.lessonView = true
        }
        if (access_right[a] == "2003" || access_right[a] == "2005") {
          this.questionView = true
        }
        if (access_right[a] == "2006" || access_right[a] == "2007") {
          this.quizView = true
        }
        if (access_right[a] == "2008" || access_right[a] == "2009") {
          this.materialView = true
        }
        if (access_right[a] == "2010" || access_right[a] == "2011") {
          this.topicView = true
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Bank Materi Soal" }]);
    this.checkAccess()
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>