<template>
  <div class="animate__animated animate__fadeIn">
    <form @submit.stop.prevent="dataAdd()">
      <b-form-group id="input-group-photo">
        <label class="col-xl-3 col-lg-5 col-form-label pl-0">Gambar</label>
        <div class="col-lg-9 col-xl-6 pl-0 pt-0">
          <div class="image-input image-input-outline" id="kt_profile_avatar">
            <div class="image-input-wrapper">
              <img :src="photo" alt="" />
            </div>
            <label
              class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
              data-action="change"
              data-toggle="tooltip"
              title=""
              data-original-title="Change avatar"
            >
              <i class="fa fa-pen icon-sm text-muted"></i>
              <input
                type="file"
                name="profile_avatar"
                accept=".png, .jpg, .jpeg"
                @change="onFileChange($event)"
              />
              <input type="hidden" name="profile_avatar_remove" />
            </label>
            <span
              class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
              data-action="cancel"
              data-toggle="tooltip"
              title="Cancel avatar"
            >
              <i class="ki ki-bold-close icon-xs text-muted"></i>
            </span>
            <span
              class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
              data-action="remove"
              data-toggle="tooltip"
              title="Remove avatar"
              @click="current_photo = null"
            >
              <i class="ki ki-bold-close icon-xs text-muted"></i>
            </span>
          </div>
        </div>
      </b-form-group>

      <b-form-group id="input-group-name" label="Nama:" label-for="input-name">
        <b-form-input
          id="input-name"
          v-model="post.name"
          placeholder="Nama Topik"
        ></b-form-input>
        <small class="text-danger">{{ error.name }}</small>
      </b-form-group>

      <b-form-group
        id="input-group-topics"
        label="Parent Topik:"
        label-for="input-topics"
      >
        <treeselect
          v-model="post.parent_id"
          :multiple="false"
          placeholder="Pilih Topik"
          :options="topics"
        />
        <small class="text-danger">{{ error.parent_id }}</small>
      </b-form-group>

      <div class="form-group">
        <label>Tipe Akses</label>
        <select v-model="post.access_type" class="form-control">
          <option value="0">Publik</option>
          <option value="1">Private</option>
        </select>
        <span class="form-text text-muted">Pilih Tipe Akses</span>
      </div>

      <b-form-group id="input-group-description">
        <label for="input-description"
          >Deskripsi: <em class="text-muted">opsional</em></label
        >
        <b-form-textarea
          id="input-description"
          v-model="post.description"
          placeholder="Deskripsi Format"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.description }}</small>
      </b-form-group>

      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="handleContent"
      >
        Batal
      </b-button>
    </form>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
export default {
  props: {
    post: Object,
    route: String,
    userData: Object
  },
  data() {
    return {
      error: {
        name: "",
        image: "",
        description: "",
        parent_id: "",
        access_type: "",
      },
      current_photo: null,
      default_photo: "/img/default/topic-default.svg",
      topics: [],
    };
  },
  methods: {
    handleContent() {
      this.$emit("handleContent", "list");
    },
    getTopics() {
      ApiService.setHeader();
      ApiService.get(`api/topics/my-topics/${this.userData.id}`)
        .then((response) => {
          this.newTopics = response.data.data;
          // =====input parentid

          let induk = [
            {
              id: 0,
              name: "Induk Topik",
            },
          ];
          let topics = response.data.data;
          let merged = []
            //("response", response)
            //("response panjang", response.data.data.length)
          if(response.data.data.length > 0){
            //("benar topik lebih dari nol")
            merged = [...induk, ...topics];
          }else{
            //("salah", response.data.data.length)
            merged = induk;
          }
          //("merged", merged);
          //(merged);

          this.topics = merged.map((elem) => ({
            id: elem.id,
            label: elem.name,
          }));

          //(this.topics);
          // =====akhir input parent id
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.post.image = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.post.image = "";
    },
    async dataAdd() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.post)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$emit("dataAdd");
        // this.$router.push("/masters/users");
      }
    },
  },
  mounted() {
    this.getTopics();
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 200px !important;
  height: 200px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>